import { PageProps, navigate } from 'gatsby';
import * as React from 'react';
import { useEffect } from 'react';

interface NotFoundPageProps extends PageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  useEffect(() => {
    navigate('/');
  }, []);

  return null;
};

export default NotFoundPage;
